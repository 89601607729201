import React from 'react';

export default ({ minutes, className }) => {
  const hours = Math.floor(minutes / 60.0);
  let formatted = `${hours}h`;
  const mins = minutes - hours * 60;
  if (mins > 0) {
    formatted += ` ${mins}m`;
  }
  return <span className={className}>{formatted}</span>;
};
