import React from 'react';
import { connect } from 'helpers';

import HelpIndex from 'components/pages/client/help/index';

class HelpIndexContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Help' });
  }

  render() {
    return <HelpIndex />;
  }

}

export default connect(HelpIndexContainer);
