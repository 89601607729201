import React from 'react';
import { connect } from 'helpers';

import ClientSettingsAccount from 'components/pages/client/settings/account';

class ClientSettingsAccountContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Settings' });
  }

  render() {
    return <ClientSettingsAccount />;
  }

}

export default connect(ClientSettingsAccountContainer);
