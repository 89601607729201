import React from 'react';
import { connect } from 'helpers';

import ClientContractorsIndex from 'components/pages/client/contractors/index';

class ClientContractorsIndexContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Contractors' });
  }

  render() {
    return <ClientContractorsIndex {...this.props} />;
  }

}

const mapStateToProps = ({ projects, contractors }) => Object.assign({}, {
  projects: projects.data,
  contractors: contractors.data,
});

export default connect(ClientContractorsIndexContainer, mapStateToProps);
