import React from 'react';
import { connect } from 'helpers';

import ClientChatIndex from 'components/pages/client/chat/index';

class ClientChatIndexContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Chat' });
  }

  render() {
    return <ClientChatIndex />;
  }

}

export default connect(ClientChatIndexContainer);
