import { padStart } from 'lodash';
import React from 'react';

export default ({ seconds, className }) => {
  const secs = Math.floor(seconds % 60);
  const mins = Math.floor(seconds / 60.0) % 60;
  const hours = Math.floor(seconds / 3600.0) % 60;
  if (hours === 0) {
    return <span className={className}>{mins}:{padStart(secs, 2, '0')}</span>;
  }
  return <span className={className}>{hours}h {mins}m</span>;
};
