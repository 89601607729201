import React from 'react';
import { connect } from 'helpers';

import ClientReportsIndex from 'components/pages/client/reports/index';

const props = {

  days: [
    { day: 'Sun', minutes:  4 * 60 + 30 },
    { day: 'Mon', minutes: 24 * 60 + 28 },
    { day: 'Tue', minutes: 12 * 60 + 30 },
    { day: 'Wed', minutes: 24 * 60 + 12 },
    { day: 'Thu', minutes: 19 * 60 + 57 },
    { day: 'Fri', minutes: 20 * 60 + 10 },
    { day: 'Sat', minutes:  5 * 60 + 15 },
  ],

};

class ClientReportsIndexContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Reports' });
  }

  render() {
    return <ClientReportsIndex {...props} {...this.props} />;
  }

}

const mapStateToProps = ({ projects }) => Object.assign({}, {
  projects: projects.data,
  contractors: projects.data[0].contractors,
});

export default connect(ClientReportsIndexContainer, mapStateToProps);
