import T from 'actions/types';

import daria from 'tmp/daria.jpg';

export const contractors = [
  {
    firstName: 'April',
    lastName: 'Terry',
    slug: 'april-terry',
    email: 'april@terrydesigns.com',
    timer: 'active',
    photoUrl: 'https://randomuser.me/api/portraits/women/72.jpg',
    hourlyRate: 100,
    minutes: 12 * 60 + 30,
  },
  {
    firstName: 'Adam',
    lastName: 'West',
    email: 'me@adamwest.com',
    slug: 'adam-west',
    timer: 'inactive',
    photoUrl: 'https://randomuser.me/api/portraits/men/20.jpg',
    hourlyRate: 80,
    minutes: 8 * 60 + 7,
  },
  {
    firstName: 'Daria',
    lastName: 'Baltina',
    email: 'daria.baltina@gmail.com',
    slug: 'daria-baltina',
    timer: 'paused',
    photoUrl: daria,
    hourlyRate: 125,
    minutes: 7 * 60 + 28,
  },
  {
    firstName: 'Eleanor',
    lastName: 'Redding',
    email: 'eleanor@myspace.com',
    slug: 'eleanor-redding',
    timer: 'inactive',
    photoUrl: 'https://randomuser.me/api/portraits/women/50.jpg',
    hourlyRate: 65,
    minutes: 4 * 60 + 40,
  },
];

const initialState = {
  data: contractors,
};

export default (state=initialState, action) => {
  if (action.type !== T.SET_CONTRACTORS) {
    return state;
  }

  return action.contractors;
};
