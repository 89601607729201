import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';

import logo from 'images/logo.svg';

export default (
  <div className="wrapper">
    <section className="sidebar">
      <img src={logo} className="logo hidden md:block" alt="" />
      <ul className="nav" />
      <div className="sidebar__footer md:block">
        <p className="text-xs px-2 mb-2">
            Made with <FontAwesomeIcon icon={faHeart} className="text-red opacity-75" /> in <br />
            Boulder &amp; Khmelnitsky
        </p>
        <p>
            &copy; ZusBus LLC
        </p>
      </div>
    </section>
    <section className="flex-1" />
  </div>
);
