import React from 'react';
import { connect } from 'helpers';

import ContractorProjectsIndex from 'components/pages/contractor/projects/index';

class ContractorProjectsIndexContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Projects' });
  }

  render() {
    return <ContractorProjectsIndex {...this.props} />;
  }

}

const mapStateToProps = ({ projects }) => Object.assign({}, {
  projects: projects.data,
});

export default connect(ContractorProjectsIndexContainer, mapStateToProps);
