import React from 'react';
import Humanize from 'humanize-plus';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle, faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import { TimerDuration } from 'components/utilities';

export default class Timer extends React.Component {

  interval = null

  state = {
    timerStartedAt: null,
    timerEndedAt: null,
    paused: false,
  }

  getStatus = () => {
    if (this.interval) {
      return 'running';
    }
    if (this.state.paused) {
      return 'paused';
    }
    return 'stopped';
  }

  onClick = () => {
    const status = this.getStatus();

    if (status === 'stopped') {

      this.setState({
        timerStartedAt: (new Date()).getTime() / 1000.0,
      });

      this.interval = setInterval(this.forceUpdate.bind(this), 250);

    } else if (status === 'running') {

      this.setState({
        timerEndedAt: (new Date()).getTime() / 1000.0,
        paused: true,
      });
      clearInterval(this.interval);
      this.interval = null;

    } else { // status === 'paused'

      let time = (new Date()).getTime() / 1000.0;
      const secondsElapsed = Math.floor(this.getDurationInSeconds());

      this.setState({
        timerStartedAt: time - secondsElapsed,
        paused: false,
      });

      this.interval = setInterval(this.forceUpdate.bind(this), 250);

    }
  }

  logTime = () => {
    const seconds = this.getDurationInSeconds();

    console.log('logTime', Humanize.toFixed(seconds, 2));

    this.setState({ timerStartedAt: null });
    clearInterval(this.interval);
    this.interval = null;
  }

  getDurationInSeconds = () => {
    const { timerStartedAt } = this.state;

    if (!timerStartedAt) {
      return null;
    }

    const now = (new Date()).getTime() / 1000.0;
    return now - timerStartedAt;
  }

  render() {
    const status = this.getStatus();
    const seconds = this.getDurationInSeconds();

    return (
      <div className="timer">
        <div className="timer__button hover:text-green" onClick={this.onClick}>
          {(() => {
            if (status === 'stopped') {
              return <FontAwesomeIcon icon={faPlayCircle} />;
            } else if (status === 'running') {
              return <FontAwesomeIcon icon={faPauseCircle} className="text-yellow" />;
            } else {
              return <FontAwesomeIcon icon={faPlayCircle} />;
            }
          })()}
        </div>
        <div className="timer__time">
          <TimerDuration seconds={seconds || 0} />
          {status === 'paused' && (
            <div className="timer__time__logit" onClick={this.logTime}>log your time</div>
          )}
        </div>
      </div>
    );
  }

}
