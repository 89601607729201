import { map } from 'lodash';
import cx from 'classnames';
import React from 'react';

import { Link } from 'react-router-dom';

const links = [
  {
    text: 'Profile',
    uri: '/client/settings/account',
  },
  {
    text: 'Payment methods',
    uri: '/client/settings/payment-methods',
  },
  {
    text: 'Users',
    uri: '/client/settings/users',
  },
  {
    text: 'Subscription',
    uri: '/client/settings/subscription',
  },
];

export default ({ active }) => (
  <ul className="secondary-nav">
    {map(links, (link, i) => (
      <li key={i} className="secondary-nav__item">
        <Link
          to={link.uri}
          className={cx(
            'uppercase bold-11-caps py-2 px-6 rounded-lg',
            link.text === active && 'bg-accent text-white',
            link.text !== active && 'btn-default',
          )}
        >
          {link.text}
        </Link>
      </li>
    ))}
  </ul>
);
