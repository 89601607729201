import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';

import DropDown from './drop-down';

export default () => {
  const [dates, setDates] = useState({
    startDate: moment().startOf('week').weekday(1),
    endDate: moment().endOf('day'),
  });
  const [focusedInput, setFocusedInput] = useState();

  return (
    <Fragment>
      <DropDown value="This week" />
      <div className="float-right mt-1">
        <DateRangePicker
          startDate={dates.startDate}
          startDateId="calendar"
          endDate={dates.endDate}
          endDateId="end_date"
          focusedInput={focusedInput}
          onDatesChange={setDates}
          onFocusChange={setFocusedInput}
          firstDayOfWeek={1}
          isOutsideRange={day => day.isAfter()}
          numberOfMonths={1}
          noBorder
          small
        />
      </div>
    </Fragment>
  );
};
