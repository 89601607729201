import React from 'react';
import { connect } from 'helpers';

import ClientSettingsUsers from 'components/pages/client/settings/users';

const props = {
  users: [
    {
      id: 1,
      firstName: 'Lillie',
      lastName: 'Hudson',
      email: 'lillie@greekcapitalmanagement.com',
      photoUrl: 'https://randomuser.me/api/portraits/women/81.jpg',
    },
    {
      id: 2,
      firstName: 'Evan',
      lastName: 'Parker',
      email: 'evan@greekcapitalmanagement.com',
      status: 'invited',
    },
    {
      id: 3,
      firstName: 'Derek',
      lastName: 'Wright',
      email: 'derek@greekcapitalmanagement.com',
      photoUrl: 'https://randomuser.me/api/portraits/men/46.jpg',
    },
  ],
};

class ClientSettingsUsersContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Settings' });
  }

  render() {
    return <ClientSettingsUsers {...props} />;
  }

}

export default connect(ClientSettingsUsersContainer);
