import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ projects }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <h1 className="header__title">
        {t('layout.Help')}
      </h1>
    </Fragment>
  );
};
