import React from 'react';
import { bool, number } from 'prop-types';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const Money = ({ amount, strip, className }) => {
  let formatted = formatter.format(amount);

  if (strip) {
    formatted = formatted.replace(/\.00$/, '');
  }

  return <span className={className}>{formatted}</span>;
};

Money.propTypes = {
  amount: number.isRequired,
  strip: bool,
};

Money.defaultPropTypes = {
  strip: false,
};

export default Money;
