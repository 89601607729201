import { map } from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisV, faSackDollar, faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import { Money } from 'components/utilities';

import Nav from './_nav';

const icons = {
  'Payoneer': require('images/payout-methods/payoneer.svg'),
  'Transferwise': require('images/payout-methods/transferwise.svg'),
};

const plans = [
  {
    name: 'No Fees!',
    icon: faSackDollar,
    features: [
      'Keep 100% of earnings',
      'Paid every Monday',
      'Unlimited projects',
      'Unlimited hours',
    ],
  },
  {
    name: 'Guaranteed Daily Payments',
    icon: faShieldCheck,
    features: [
      'Keep 98% of earnings',
      <span>Paid <strong>daily</strong>, overnight EEST</span>,
      'Guaranteed payments',
      'Unlimited projects & hours',
    ],
  },
];

export default ({ transactions }) => (
  <Fragment>
    <h1 className="header__title">
      Payout methods
    </h1>
    <Nav active="Payout methods" />
    <div className="columns mt-8">
      <div>
        <div className="sheet">
          <ul className="big-list">
            <li className="big-list__item">
              <div className="big-list__text">
                <img src={icons.Transferwise} className="big-list__photo rounded-none" alt="" style={{ height: 36 }} />
              </div>
              <div className="big-list__actions relative">
                <span className="label-success mr-4">primary</span>
                <FontAwesomeIcon icon={faEllipsisV} />
                <ul className="dropdown">
                  <li className="dropdown__item">
                      Make primary
                  </li>
                  <li className="dropdown__item">
                      Disconnect
                  </li>
                </ul>
              </div>
            </li>
            <li className="big-list__item">
              <div className="big-list__text">
                <img src={icons.Payoneer} height={44} className="big-list__photo rounded-none" alt="" />
              </div>
              <div className="big-list__actions relative">
                <FontAwesomeIcon icon={faEllipsisV} />
              </div>
            </li>
            <li className="big-list__item">
              <div className="big-list__text">
                <img src={icons.Transferwise} className="big-list__photo rounded-none" alt="" style={{ height: 36 }} />
              </div>
              <div className="big-list__actions">
                <button className="btn-accent">connect</button>
              </div>
            </li>
          </ul>
        </div>
        <div className="plans lg:flex mt-12 px-0">
          {map(plans, (plan, i) => (
            <div
              key={i}
              className={cx('plans__plan mx-0', {
                'plans__plan--active': i === 1,
                'mr-6': i === 0,
              })}
            >
              <div className="plan__icon">
                <FontAwesomeIcon icon={plan.icon} />
              </div>
              <div className="plan__name">
                {plan.name}
              </div>
              <div className="plan__cost">
                {plan.cost}
                {plan.perMonth && (
                  <span className="plan__per-month">
                    /month
                  </span>
                )}
              </div>
              <ul className="plan__features">
                {map(plan.features, (feature, i) => (
                  <li key={i} className="plan__features__feature">
                    <FontAwesomeIcon icon={faCheck} />
                    {feature}
                  </li>
                ))}
              </ul>
              <button className="plan__button btn-accent">
                {i === 1 ? 'Current plan' : 'Select plan'}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="flex-1 sheet">
          <div className="sheet__title">
            Transaction history
          </div>
          <ul className="simple-list">
            {map(transactions, (payment, i) => (
              <li key={i} className="simple-list__item">
                <div className="simple-list__item__primary flex" style={{ flex: 2 }}>
                  <div className="inline flex-1 my-3">
                    {payment.company}
                  </div>
                </div>
                <div className="simple-list__item__secondary" style={{ flex: 1 }}>
                  <Money amount={payment.amount} className="semibold-14 text-grayscale-black" />
                  <div className="reg-12 mt-1">
                    {moment(payment.created_at).format('MMMM D, YYYY')}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </Fragment>
);
