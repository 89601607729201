import T from 'actions/types';

const initialState = {
  sidebar: '',
};

export default (state=initialState, action) => {
  if (action.type !== T.SET_LAYOUT_PROPS) {
    return state;
  }

  return Object.assign({}, state, action.props);
};
