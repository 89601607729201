import React from 'react';
import { connect } from 'helpers';

import ClientSettingsSubscription from 'components/pages/client/settings/subscription';

class ClientSettingsSubscriptionContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Settings' });
  }

  render() {
    return <ClientSettingsSubscription />;
  }

}

export default connect(ClientSettingsSubscriptionContainer);
