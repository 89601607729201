import React from 'react';
import { connect } from 'helpers';

import ContractorSettingsProfile from 'components/pages/contractor/settings/profile';

class ContractorSettingsProfileContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Settings' });
  }

  render() {
    return <ContractorSettingsProfile />;
  }

}

export default connect(ContractorSettingsProfileContainer);
