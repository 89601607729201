import React from 'react';
import { func } from 'prop-types';

import { Link } from 'react-router-dom';

import logo from 'images/logo.svg';

export default class UnauthenticatedAuthLogin extends React.Component {

  static propTypes = {
    login: func.isRequired,
  }

  state = {
    user: {
      email: '',
      password: '',
    },
  }

  onChange = (e) => {
    e.preventDefault();

    const user = Object.assign(this.state.user, {
      [e.target.name]: e.target.value,
    });
    this.setState({ user });
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { login } = this.props;
    const { user } = this.state;

    const opts = {
      method: 'POST',
      body: JSON.stringify({ session: user }),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch('/api/sessions', opts)
      .then((r) => r.ok ? Promise.resolve(r.json()) : Promise.reject(r.json()))
      .then(resp => login(resp))
      .catch(console.error);
  }

  render () {

    return (
      <div className="auth-screen">
        <img src={logo} className="auth-screen__logo" alt="" />
        <div className="auth-screen__modal">

          <form autoComplete="off" className="form" onSubmit={this.onSubmit}>

            <div className="form__form-group">
              <label>Email</label>
              <input
                type="text"
                name="email"
                placeholder="Email address"
                onChange={this.onChange}
                tabIndex={1}
                autoFocus
              />
            </div>

            <div className="form__form-group">
              <Link to="/auth/forgot" className="float-right reg-12 text-gray">
                Forgot password?
              </Link>
              <label>Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={this.onChange}
                tabIndex={2}
              />
            </div>

            <button className="btn-accent w-full" tabIndex={3}>Login</button>

          </form>

          <p className="auth-screen__footer">
            Don't have an account?
            <Link to="/signup">Signup</Link>
          </p>

        </div>
      </div>
    );
  }

}
