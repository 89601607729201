import { kebabCase, map } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';

export default ({ projects }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <h1 className="header__title">
        {t('layout.Projects')}
      </h1>

      <div className="sheet mx-8">

        <div className="sheet__title">
          {t('projects.ActiveProjects')}
        </div>

        <ul className="big-list">
          {map(projects, project => (
            <li key={project.id} className="big-list__item">
              <img src={project.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
              <div className="big-list__text">
                <Link to={`/contractor/projects/${kebabCase(project.name)}`} className="big-list__text__title my-2">
                  {project.name}
                </Link>
                <div className="big-list__text__subtitle">
                  {project.clients[0].firstName} {project.clients[0].lastName}
                </div>
              </div>
              <div className="big-list__actions relative">
                <FontAwesomeIcon icon={faEllipsisV} />
                {project.name === 'Synack' && (
                  <ul className="dropdown">
                    <li className="dropdown__item">
                      {t('projects.LeaveProject')}
                    </li>
                  </ul>
                )}
              </div>
            </li>
          ))}
        </ul>

        <div className="big-list__footer-link">
          <span>
            {t('projects.showArchivedProjects')}
          </span>
        </div>

      </div>
    </Fragment>
  );
};
