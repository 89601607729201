import T from 'actions/types';

const initialState = {
  data: null,
};

export default (state=initialState, action) => {
  if (action.type !== T.SET_USER) {
    return state;
  }

  return {
    data: action.user,
  };
};
