import { chain, kebabCase, map } from 'lodash';
import cx from 'classnames';
import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { Duration } from 'components/utilities';
import { DateRange } from 'components/shared';

export default ({ project, contractors, workLog, days }) => {

  const max = chain(days).map('minutes').max().value();
  const sum = chain(days).map('minutes').sum().value();

  return (
    <Fragment>
      <h1 className="header__title">
        {project.name}
      </h1>

      <div className="columns">
        <div>
          <div className="sheet sheet--padding">
            <DateRange />
          </div>
          <div className="sheet px-3 py-6">
            <ul className="flex">
              {map(project.contractors, (contractor, i) => (
                <li key={i} className="inline-block flex-1 mx-3">
                  <Link
                    to={`/client/contractors/${kebabCase(`${contractor.firstName} ${contractor.lastName}`)}`}
                    className="relative block"
                  >
                    <img
                      src={contractor.photoUrl}
                      className="rounded-full width-full"
                      alt=""
                    />
                    <FontAwesomeIcon
                      icon={faClock}
                      className={cx(
                        'absolute bottom-0 right-0 text-2xl sm:text-3xl bg-white rounded-full p-1',
                        contractor.timer === 'active' && 'text-green',
                        contractor.timer === 'paused' && 'text-yellow',
                        contractor.timer === 'inactive' && 'text-grayscale-light',
                      )}
                      title={(() => {
                        if (contractor.timer === 'active') {
                          return 'Currently working';
                        }
                        if (contractor.timer === 'paused') {
                          return 'Timer is paused';
                        }
                        return 'Not currently working';
                      })()}
                    />
                  </Link>
                  <div className="text-center semibold-14 mt-2">
                    {contractor.firstName} {contractor.lastName[0]}
                  </div>
                </li>
              ))}
            </ul>

          </div>
          <div className="sheet sheet--padding">
            <div className="">
              <ul className="flex items-end mt-2 mb-4">
                {map(days, (day, i) => (
                  <li key={i} className="flex-1 relative px-2 border-b border-grayscale-divider" style={{ height: 180.0 * day.minutes / max }}>
                    <div className="bg-accent-light h-full bold-11 text-gray text-center border-t border-accent">
                      <div className="relative" style={{ bottom: 15 }}>
                        <Duration minutes={day.minutes} />
                      </div>
                      <div className="absolute left-0 right-0 text-center" style={{ bottom: -20 }}>
                        {day.day}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Time worked this week
            </div>
            <div className="text-4xl font-bold text-center mt-1">
              <Duration minutes={sum} />
            </div>
          </div>
          <div className="sheet">
            <div className="sheet__title">
              Work log
            </div>
            <ul className="simple-list">
              {map(workLog, (entry, i) => (
                <li key={i} className="simple-list__item">
                  <div className="simple-list__item__primary">
                    <Link to={`/client/contractors/${kebabCase(`${entry.firstName} ${entry.lastName}`)}`}>
                      {entry.firstName} {entry.lastName}
                    </Link>
                  </div>
                  <div className="simple-list__item__secondary">
                    {entry.time} {entry.date}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

    </Fragment>
  );
};
