import { map } from 'lodash';
import cx from 'classnames';
import React from 'react';
import { connect } from 'helpers';
import { useTranslation, Trans } from 'react-i18next';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faChartBar, faCog, faCommentsAlt, faHeart, faSackDollar, faUsers } from '@fortawesome/pro-solid-svg-icons';

import logo from 'images/logo.svg';
import todd from 'tmp/todd.png';

const navs = {
  client: [
    {
      title: 'Projects',
      icon: faBriefcase,
      uri: '/client/projects',
    },
    {
      title: 'Chat',
      icon: faCommentsAlt,
      uri: '/client/chat',
    },
    {
      title: 'Contractors',
      icon: faUsers,
      uri: '/client/contractors',
    },
    {
      title: 'Reports',
      icon: faChartBar,
      uri: '/client/reports',
    },
    {
      title: 'Settings',
      icon: faCog,
      uri: '/client/settings/account',
    },
    /*
    {
      title: 'Help',
      icon: faUserHeadset,
      uri: '/client/help',
    },
    */
  ],
  contractor: [
    {
      title: 'Projects',
      icon: faBriefcase,
      uri: '/contractor/projects',
    },
    {
      title: 'Chat',
      icon: faCommentsAlt,
      uri: '/contractor/chat',
    },
    {
      title: 'Earnings',
      icon: faSackDollar,
      uri: '/contractor/earnings',
    },
    {
      title: 'Settings',
      icon: faCog,
      uri: '/contractor/settings/profile',
    },
    /*
    {
      title: 'Help',
      icon: faUserHeadset,
      uri: '/contractor/help',
    },
    */
  ],
};


const ClientLayout = ({ children, active }) => {
  const { t } = useTranslation();

  const isContractor = window.location.pathname.match(/^\/contractor\//);
  const nav = isContractor ? navs.contractor : navs.client;

  return (
    <div className="wrapper">
      <section className="sidebar">
        <Link to="/">
          <img src={logo} className="logo hidden md:block" alt="" />
        </Link>
        <ul className="nav">
          {map(nav, (item, i) => (
            <li
              key={i}
              className={cx(
                'nav__item',
                item.title === active && 'nav__item--active',
              )}
            >
              {item.uri ? (
                <Link to={item.uri}>
                  <span className="nav__item__icon">
                    <FontAwesomeIcon icon={item.icon} />
                  </span>
                  <span className="nav__item__title md:inline-block">
                    {t(`layout.${item.title}`)}
                  </span>
                </Link>
              ) : (
                <div onClick={item.onClick} className="cursor-pointer">
                  <span className="nav__item__icon">
                    <FontAwesomeIcon icon={item.icon} />
                  </span>
                  <span className="nav__item__title md:inline-block">
                    {t(`layout.${item.title}`)}
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="sidebar__footer md:block">
          <p className="text-xs px-2 mb-2">
            <Trans i18nKey="layout.MadeWithLoveIn">
              Made with <FontAwesomeIcon icon={faHeart} className="text-red opacity-75" /> in
            </Trans>
            <br />Boulder &amp; Khmelnitsky
          </p>
          <p>
            &copy; ZusBus LLC
          </p>
        </div>
      </section>
      <section className="flex-1">
        <header className="header__profile md:block">
          <Link to="/client/settings/account" className="header__profile__name">
            Todd Zusman
          </Link>
          <Link to="/client/settings/account">
            <img src={todd} width={40} height={40} className="header__profile__photo" alt="" />
          </Link>
        </header>
        <section className="content">
          {children}
        </section>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  active: state.layoutProps.sidebar,
});

export default connect(ClientLayout, mapStateToProps);
