import { find } from 'lodash';
import React from 'react';
import { connect } from 'helpers';

import ClientContractorsShow from 'components/pages/client/contractors/show';

import gcm from 'tmp/gcm.png';

const props = {

  days: [
    { day: 'Sun', minutes: 1 * 60 + 30 },
    { day: 'Mon', minutes: 4 * 60 + 20 },
    { day: 'Tue', minutes: 8 * 60 + 30 },
    { day: 'Wed', minutes: 5 * 60 + 20 },
    { day: 'Thu', minutes: 6 * 60 + 55 },
    { day: 'Fri', minutes: 3 * 60 + 10 },
    { day: 'Sat', minutes: 0 * 60 + 50 },
  ],


  workLog: [
    {
      project: 'Greek Capital Management',
      minutes: 1 * 60 + 28,
      started_at: 'Today at 3:10am MDT',
      photoUrl: gcm,
    },
    {
      project: 'Greek Capital Management',
      minutes: 3 * 60 + 25,
      started_at: 'Yesterday at 5:42am MDT',
      photoUrl: gcm,
    },
    {
      project: 'Greek Capital Management',
      minutes: 5 * 60 + 37,
      started_at: 'Thursday at 2:38am MDT',
      photoUrl: gcm,
    },
    {
      project: 'Greek Capital Management',
      minutes: 3 * 60 + 51,
      started_at: 'Wednesday at 2:12am MDT',
      photoUrl: gcm,
    },
  ],
};

class ClientContractorsShowContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Contractors' });
  }

  render() {
    return <ClientContractorsShow {...props} {...this.props} />;
  }

}

const mapStateToProps = ({ contractors }, ownProps) => {
  const { slug } = ownProps.match.params;
  const contractor = find(contractors.data, { slug });
  return { contractor };
};

export default connect(ClientContractorsShowContainer, mapStateToProps);
