import { map } from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { Money } from 'components/utilities';

import Nav from './_nav';

const icons = {
  'DinersClub': require('images/payment-methods/DinersClub.svg'),
  'Mastercard': require('images/payment-methods/_Mastercard.svg'),
  'Visa': require('images/payment-methods/Visa.svg'),
  'Discover': require('images/payment-methods/Discover.svg'),
  'AmEx': require('images/payment-methods/AmEx.svg'),
  'JCB': require('images/payment-methods/JCB.svg'),
  'Chase': require('images/payment-methods/bank.svg'),
};

export default ({ paymentMethods, payments }) => (
  <Fragment>
    <h1 className="header__title">
      Settings
    </h1>
    <Nav active="Payment methods" />
    <div className="columns mt-8">
      <div>
        <div className="sheet">
          <div className="sheet__title">
            Payment methods
          </div>
          <ul className="big-list">
            {map(paymentMethods, (paymentMethod, i) => (
              <li key={i} className="big-list__item">
                <img src={icons[paymentMethod.company]} className="big-list__photo rounded-none" alt="" style={{ height: 32 }} />
                <div
                  className={cx('big-list__text', {
                    'mt-1': !paymentMethod.expiration_mo,
                  })}
                >
                  {paymentMethod.company} ending in {paymentMethod.last4}
                  {paymentMethod.expiration_mo && (
                    <div className="big-list__text__subtitle">
                      {paymentMethod.expiration_mo} / 20{paymentMethod.expiration_yr}
                    </div>
                  )}
                </div>
                <div className="big-list__actions relative">
                  {i === 0 && <span className="label-success mr-4">primary</span>}
                  {i === 1 && <span className="label-danger mr-4 cursor-pointer">unverified</span>}
                  {i === 2 && <span className="label-warning mr-4">pending</span>}
                  <FontAwesomeIcon icon={faEllipsisV} />
                  {i === 2 && (
                    <ul className="dropdown">
                      <li className="dropdown__item">
                        Verify micro-deposits
                      </li>
                      <li className="dropdown__item">
                        Make primary
                      </li>
                      <li className="dropdown__item">
                        Remove
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            ))}
            <li className="big-list__item">
              <button className="btn-default ml-8">Add a credit card</button>
              <button className="btn-default ml-6">Add a bank account</button>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="flex-1 sheet">
          <div className="sheet__title">
            Transaction history
          </div>
          <ul className="simple-list">
            {map(payments, (payment, i) => (
              <li key={i} className="simple-list__item">
                <div className="simple-list__item__primary flex" style={{ flex: 2 }}>
                  <div className="inline flex-1">
                    {payment.company}
                    <span className="text-gray"> ending in </span>
                    {payment.last4}
                    <Link to="/download/invoice/1234" className="reg-12 mt-1 text-gray block">
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      Download invoice
                    </Link>
                  </div>
                </div>
                <div className="simple-list__item__secondary" style={{ flex: 1 }}>
                  <Money amount={payment.amount} className="semibold-14 text-grayscale-black" />
                  <div className="reg-12 mt-1">
                    {moment(payment.created_at).format('MMMM D, YYYY')}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    <div className="flex px-4">
      <div className="flex-1 px-4">
        <div className="sheet sheet--padding">
          <div className="sheet__title">
            Add a credit card
            <div className="semibold-12 text-gray mt-2">Credit cards have a 2.99% processing charge.</div>
          </div>

          <div className="form__form-group">
            <label className="uppercase">
              Card number
            </label>
            <input type="text" placeholder="xxxx xxxx xxxx xxxx" />
          </div>

          <div className="form__form-group">
            <label className="uppercase">
              Cardholder name
            </label>
            <input type="text" placeholder="Your name as it appears on the card" />
          </div>

          <div className="flex">
            <div className="flex-1 pr-2">

              <div className="form__form-group">
                <label className="uppercase">
                  Expiration date
                </label>
                <input type="text" placeholder="mm / yy" />
              </div>

            </div>
            <div className="flex-1 pl-2">

              <div className="form__form-group">
                <label className="uppercase">
                  CVV
                </label>
                <input type="text" placeholder="***" />
              </div>

            </div>
          </div>

          <hr className="border-grayscale-divider mb-6" />

          <div className="form__form-group">
            <label className="uppercase">
              Billing address
            </label>
            <input type="text" placeholder="Street address" className="mb-2" />
            <input type="text" placeholder="Unit or Suite" />
          </div>

          <button className="btn-accent">Save credit card</button>

        </div>
      </div>
      <div className="flex-1 px-4">
        <div className="sheet sheet--padding">
          <div className="sheet__title">
            Add a bank account
            <div className="semibold-12 text-gray mt-2">ACH payments have a 0.99% processing charge.</div>
          </div>

          <div className="form__form-group">
            <label className="uppercase">
              Routing number
            </label>
            <input type="text" placeholder="xxx xxx xxx" />
          </div>

          <div className="form__form-group">
            <label className="uppercase">
              Account number
            </label>
            <input type="text" placeholder="xxxx xxxx xxxx xxxx" />
          </div>

          <hr className="border-grayscale-divider mb-6" />

          <div className="form__form-group">
            <label className="uppercase">
              Billing address
            </label>
            <input type="text" placeholder="Street address" className="mb-2" />
            <input type="text" placeholder="Unit or Suite" />
          </div>

          <button className="btn-accent">Save bank details</button>

        </div>
      </div>
    </div>
  </Fragment>
);
