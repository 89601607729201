import { map } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';

import bonusly from 'tmp/bonusly.png';

export default ({ projects }) => {
  const { t } = useTranslation();
  const [ showArchived, setShowArchived ] = useState(false);

  return (
    <Fragment>
      <h1 className="header__title">
        {t('layout.Projects')}
      </h1>

      <div className="columns">
        <div className="sheet">

          <div className="sheet__title">
            Active projects
          </div>

          <ul className="big-list">
            {map(projects, project => (
              <li key={project.id} className="big-list__item">
                <img src={project.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
                <div className="big-list__text my-2">
                  <Link to={`/client/projects/${project.slug}`} className="big-list__text__title my-2">
                    {project.name}
                  </Link>
                </div>
                <div className="big-list__actions relative">
                  <FontAwesomeIcon icon={faEllipsisV} />
                  {project.name === 'Greek Capital Management' && (
                    <ul className="dropdown">
                      <li className="dropdown__item">
                        Update project
                      </li>
                      <li className="dropdown__item">
                        Archive project
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>

          <div className="big-list__footer-link">
            <span onClick={() => setShowArchived(!showArchived)}>
              {showArchived ? 'hide' : 'show'} archived projects
            </span>
          </div>

          {showArchived && (
            <ul className="big-list">
              <li className="big-list__item">
                <img src={bonusly} width={44} height={44} className="big-list__photo" alt="" />
                <div className="big-list__text my-2">
                  <Link to="/client/projects/bonusly" className="big-list__text__title my-2">
                    Bonusly
                  </Link>
                </div>
                <div className="big-list__actions relative">
                  <FontAwesomeIcon icon={faEllipsisV} />
                  <ul className="dropdown">
                    <li className="dropdown__item">
                      Un-archive project
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          )}

        </div>
        <div>
          <div className="sheet sheet--padding">

            <div className="sheet__title">
              Create a project
            </div>

            <form autoComplete="off" className="form">

              <div className="form__form-group">
                <label>Title</label>
                <input type="text" />
              </div>

              <div className="form__form-group">
                <label>Photo</label>
                <input type="text" placeholder="Select a photo" />
              </div>

              <button className="btn-accent semibold-14 mt-1">
                Save project
              </button>

            </form>

          </div>
        </div>
      </div>
    </Fragment>
  );
};
