import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import contractorsReducer from './contractors';
import layoutPropsReducer from './layout-props';
import projectsReducer from './projects';
import userReducer from './user';

export default (history) => combineReducers({
  router: connectRouter(history),
  contractors: contractorsReducer,
  layoutProps: layoutPropsReducer,
  projects: projectsReducer,
  user: userReducer,
});
