import React from 'react';
import { connect } from 'helpers';

import { Redirect } from 'react-router-dom';
import UnauthenticatedAuthLogin from 'components/pages/unauthenticated/auth/login';

class UnauthenticatedAuthLoginContainer extends React.Component {

  login = ({ token, user }) => {
    const { actions } = this.props;

    window.localStorage.setItem('token', token);
    actions.setUser(user);
  }

  render() {
    const { user } = this.props;

    if (user) {
      return <Redirect to={`/${user.role}`} />;
    }

    return (
      <UnauthenticatedAuthLogin
        login={this.login}
      />
    );
  }

}

const mapStateToProps = ({ user }) => ({ user: user.data });

export default connect(UnauthenticatedAuthLoginContainer, mapStateToProps);
