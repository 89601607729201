import { chain, map } from 'lodash';
import React, { Fragment } from 'react';

import { Duration, Money } from 'components/utilities';
import { DateRange, Storage, Timer } from 'components/shared';

const ClientBlock = ({ project, client }) => (
  <Fragment>
    <div className="relative inline-block">
      <img src={client.photoUrl} width={120} height={120} className="rounded-full" alt="" />
    </div>
    <div className="inline-block align-top ml-8">
      <div className="text-lg bold-16 py-2">
        {client.firstName} {client.lastName}
      </div>
      <div className="semibold-14 text-gray pb-1">
        {client.location}
      </div>
      <div className="semibold-14 text-gray pb-1">
        Local time: Sun 11:15pm
      </div>
    </div>
  </Fragment>
);

export default ({ project, client, contractor, workLog, days }) => {

  const max = chain(days).map('minutes').max().value();
  const sum = chain(days).map('minutes').sum().value();

  return (
    <Fragment>
      <h1 className="header__title">
        <img src={project.photoUrl} width={44} height={44} className="inline-block rounded-full mr-4" alt="" />
        {project.name}
      </h1>

      <div className="columns">
        <div>

          <div className="sheet sheet--padding lg:hidden">
            <ClientBlock client={client} />
          </div>

          <div className="sheet sheet--padding">
            <DateRange />
          </div>

          <div className="sheet sheet--padding">
            <ul className="flex items-end mt-2 mb-4">
              {map(days, (day, i) => (
                <li key={i} className="flex-1 relative px-2 border-b border-grayscale-divider" style={{ height: 180.0 * day.minutes / max }}>
                  <div className="bg-accent-light h-full bold-11 text-gray text-center border-t border-accent">
                    <div className="relative" style={{ bottom: 15 }}>
                      <Duration minutes={day.minutes} />
                    </div>
                    <div className="absolute left-0 right-0 text-center" style={{ bottom: -20 }}>
                      {day.day}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="sheet">
            <div className="sheet__title">
              Work log
              <button className="btn-default sheet__title__button">Add time</button>
            </div>
            <ul className="big-list">
              {map(workLog, (entry, i) => (
                <li key={i} className="big-list__item py-2">
                  <div className="big-list__text pl-8">
                    <div className="big-list__text__title my-2">
                      {entry.started_at}
                    </div>
                  </div>
                  <div className="big-list__text text-right pr-8">
                    <div className="big-list__text__title my-2">
                      <Duration minutes={entry.minutes} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="big-list__footer-link" />
          </div>

        </div>
        <div>

          <div className="sheet sheet--padding hidden lg:block">
            <ClientBlock client={client} />
          </div>

          <div className="sheet sheet--padding">
            <Timer />
          </div>

          <div className="sheet sheet--padding">
            <Storage />
          </div>

          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Time worked this week
            </div>
            <div className="text-2xl font-bold text-center mt-1">
              <Duration minutes={sum} />
            </div>
          </div>

          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Earned this week
            </div>
            <div className="text-2xl font-bold text-center mt-1">
              <Money amount={contractor.hourly * sum / 60.0} />
            </div>
          </div>

        </div>
      </div>

    </Fragment>
  );
};
