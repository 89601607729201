import { map } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';

export default ({ projects, contractors }) => {
  const { t } = useTranslation();
  const [ showArchived, setShowArchived ] = useState();

  return (
    <Fragment>
      <h1 className="header__title">
        {t('layout.Contractors')}
      </h1>

      <div className="columns">
        <div>
          <div className="sheet">

            <div className="sheet__title">
              Active contractors
            </div>

            <ul className="big-list">
              {map(contractors.slice(0, 3), (contractor, i) => (
                <li key={i} className="big-list__item">
                  <img src={contractor.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
                  <div className="big-list__text">
                    <Link to={`/client/contractors/${contractor.slug}`} className="big-list__text__title">
                      {contractor.firstName} {contractor.lastName}
                    </Link>
                    <div className="big-list__text__subtitle">
                      {contractor.email}
                    </div>
                  </div>
                  <div className="big-list__actions relative">
                    <FontAwesomeIcon icon={faEllipsisV} />
                    {contractor.firstName === 'April' && (
                      <ul className="dropdown">
                        <li className="dropdown__item">
                          Archive
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
              ))}
            </ul>

            <div className="big-list__footer-link">
              <span onClick={() => setShowArchived(!showArchived)}>
                {showArchived ? 'hide' : 'show'} archived contractors
              </span>
            </div>

            {showArchived && (
              <ul className="big-list">
                {map(contractors.slice(3, 4), (contractor, i) => (
                  <li key={i} className="big-list__item">
                    <img src={contractor.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
                    <div className="big-list__text">
                      <Link to={`/client/contractors/${contractor.slug}`} className="big-list__text__title">
                        {contractor.firstName} {contractor.lastName}
                      </Link>
                      <div className="big-list__text__subtitle">
                        {contractor.email}
                      </div>
                    </div>
                    <div className="big-list__actions relative">
                      <FontAwesomeIcon icon={faEllipsisV} />
                      <ul className="dropdown">
                        <li className="dropdown__item">
                          Un-archive {contractor.firstName}
                        </li>
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            )}

          </div>
        </div>
        <div className="sheet sheet--padding">

          <div className="sheet__title">
            Invite a contractor
          </div>

          <form autoComplete="off" className="form">

            <div className="form__form-group">
              <label>First Name</label>
              <input type="text" placeholder="Beyoncé" />
            </div>

            <div className="form__form-group">
              <label>Last Name</label>
              <input type="text" placeholder="Knowles" />
            </div>

            <div className="form__form-group">
              <label>Email</label>
              <input type="text" placeholder="designer@gmail.com" />
            </div>

            <div className="form__form-group">
              <label>Project</label>
              <select>
                <option></option>
                {map(projects, (project, i) => (
                  <option key={i}>{project.name}</option>
                ))}
              </select>
            </div>

            <div className="form__form-group">
              <label>Hourly rate</label>
              <span className="prefix">$</span>
              <input type="text" style={{ paddingLeft: '2rem' }} />
            </div>

            <button type="submit" className="btn-accent semibold-14 mt-1">
              Send an invitation
            </button>

          </form>

        </div>
      </div>
    </Fragment>
  );
};
