import { map } from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import Humanize from 'humanize-plus';
import React from 'react';

const icons = {
  folder: require('images/file-types/folder.svg'),
  ai: require('images/file-types/021-ai.svg'),
  sketch: require('images/file-types/006-sketch.svg'),
};

const path = ['Home', 'illustrations'];

const files = [
  {
    title: 'playground',
    filetype: 'folder',
    fileCount: 16,
  },
  {
    title: 'guy-feet-up.ai',
    filetype: 'ai',
    filesize: 466944,
    updatedAt: '2019-09-06',
  },
  {
    title: 'guy-feet-up.sketch',
    filetype: 'sketch',
    filesize: 362496,
    updatedAt: '2019-09-05',
  },
  {
    title: 'guy-with-cash.ai',
    filetype: 'ai',
    filesize: 523264,
    updatedAt: '2019-09-04',
  },
  {
    title: 'guy-with-cash.sketch',
    filetype: 'sketch',
    filesize: 411648,
    updatedAt: '2019-08-20',
  },
];

export default () => (
  <div className="storage">
    <ul className="storage__path">
      {map(path, (folder, i) => (
        <li key={i} className="storage__path__item">
          {i < (path.length-1) ? (
            <a href={`#${folder}`}>{folder}</a>
          ) : folder}
        </li>
      ))}
    </ul>

    <ul className="storage__files">
      {map(files, (file, i) => (
        <li key={i} className="file">
          <div
            className={cx(
              'file__icon',
              { 'file__icon--folder': file.filetype === 'folder' },
            )}
          >
            <img src={icons[file.filetype]} alt={file.filetype} />
          </div>
          <div className="file__title">
            <a href={`/download/file/${file.title}`} target="_blank" rel="noopener noreferrer">
              {file.title}
            </a>
          </div>
          <div className="file__updated">
            {moment(file.updatedAt).calendar(null, {
              sameDay: '[today]',
              lastDay: '[yesterday]',
              lastWeek: 'dddd',
              sameElse: 'MMM D',
            })}
          </div>
          <div className="file__size">
            {'filesize' in file ? (
              Humanize.filesize(file.filesize).replace(/B$/, 'b')
            ) : (
              `${file.fileCount} ${Humanize.pluralize(file.fileCount, 'file')}`
            )}
          </div>
        </li>
      ))}
    </ul>
  </div>
);
