import React from 'react';
import { connect } from 'helpers';

import ContractorSettingsPayoutMethods from 'components/pages/contractor/settings/payout-methods';

const props = {

  transactions: [
    {
      company: 'Transferwise',
      amount: 2235.90,
      created_at: '2019-08-26',
    },
    {
      company: 'Transferwise',
      amount: 3420.53,
      created_at: '2019-08-19',
    },
    {
      company: 'Payoneer',
      amount: 2950.12,
      created_at: '2019-08-12',
    },
    {
      company: 'Payoneer',
      amount: 4194.42,
      created_at: '2019-08-07',
    },
  ],

};

class ContractorSettingsPayoutMethodsContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Settings' });
  }

  render() {
    return <ContractorSettingsPayoutMethods {...props} />;
  }

}

export default connect(ContractorSettingsPayoutMethodsContainer);
