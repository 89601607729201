import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';

import rootReducer from './reducers';
import Routes from './routes';

import EmptyWrapper from 'components/empty-wrapper';

/* eslint-disable */
/*
function initFreshChat() {
  window.fcWidget.init({
    token: 'd1deea15-abe1-497c-ba8a-9fcf9d16256e',
    host: 'https://wchat.freshchat.com',
  });
  document.getElementById("fc_widget").className = 'hidden';
}
function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement('script')).id=t,e.async=!0,e.src='https://wchat.freshchat.com/js/widget.js',e.onload=initFreshChat,i.head.appendChild(e));}function initiateCall(){initialize(document,'freshchat-js-sdk');}window.addEventListener?window.addEventListener('load',initiateCall,!1):window.attachEvent('load',initiateCall,!1);
*/
/* eslint-enable */

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middleware = [routerMiddleware(history), sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(history),
  composeEnhancers(
    applyMiddleware(...middleware),
  ),
);

function AppRouter() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router>
          <Suspense fallback={EmptyWrapper}>
            <Routes />
          </Suspense>
        </Router>
      </ConnectedRouter>
    </Provider>
  );
}

export default AppRouter;
