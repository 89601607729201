import { map } from 'lodash';
import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faUser } from '@fortawesome/pro-solid-svg-icons';

import Nav from './_nav';

export default ({ users }) => (
  <Fragment>
    <h1 className="header__title">
      Settings
    </h1>
    <Nav active="Users" />
    <div className="columns mt-8">
      <div className="sheet flex-1 mx-8">
        <div className="flex-1 sheet__title">
          Users
        </div>
        <div className="flex">
          <ul className="big-list flex-1">
            {map(users, user => (
              <li key={user.id} className="big-list__item">
                {user.photoUrl ? (
                  <img src={user.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
                ) : (
                  <div className="big-list__photo bg-grayscale-lightest text-center text-gray text-2xl" style={{ width: 44, height: 44 }}>
                    <FontAwesomeIcon icon={faUser} className="relative" style={{ top: 3 }} />
                  </div>
                )}
                <div className="big-list__text">
                  {user.firstName} {user.lastName}
                  <div className="big-list__text__subtitle">
                    {user.email}
                  </div>
                </div>
                <div className="big-list__actions relative">
                  {user.status === 'invited' && (
                    <span className="label-warning mr-6 relative" style={{ bottom: 1 }}>invited</span>
                  )}
                  <FontAwesomeIcon icon={faEllipsisV} />
                  {user.firstName === 'Evan' && (
                    <ul className="dropdown">
                      <li className="dropdown__item">
                        Re-send invitation
                      </li>
                      <li className="dropdown__item">
                        Remove
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="sheet sheet--padding flex-1 mr-8">

        <div className="sheet__title mb-2">
          Invite a user
        </div>
        <p className="mb-4 bold-11 text-gray">
          Note: this is not where you <Link to="/client/contractors" className="underline">invite contractors</Link>. Users have access to your contractors&apos; hours &amp; earnings.
        </p>

        <form autoComplete="off" className="form">

          <div className="form__form-group">
            <label>First Name</label>
            <input type="text" placeholder="Beyoncé" />
          </div>

          <div className="form__form-group">
            <label>Last Name</label>
            <input type="text" placeholder="Knowles" />
          </div>

          <div className="form__form-group">
            <label>Email</label>
            <input type="text" placeholder="name@yourcompany.com" />
          </div>

          <button type="submit" className="btn-accent semibold-14 mt-1">
            Send an invitation
          </button>

        </form>

      </div>
    </div>
  </Fragment>
);
