import { chain, kebabCase, map } from 'lodash';
import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { Duration, Money } from 'components/utilities';
import { DateRange } from 'components/shared';

const ContractorBlock = ({ contractor }) => (
  <Fragment>
    <div className="relative inline-block">
      <img src={contractor.photoUrl} width={120} height={120} className="rounded-full" alt="" />
      <FontAwesomeIcon icon={faClock} className="text-green absolute right-0 bottom-0 text-3xl sm:text-4xl bg-white rounded-full p-1" />
    </div>
    <div className="inline-block align-top ml-8">
      <div className="text-lg bold-16 py-2">
        {contractor.firstName} {contractor.lastName}
      </div>
      <div className="semibold-14 text-gray pb-1">
        Khmelnytskyi, Ukraine
      </div>
      <div className="semibold-14 text-gray pb-1">
        Local time: Mon 8:15am
      </div>
      <div className="semibold-14 text-gray">
        Hourly rate: <Money amount={contractor.hourlyRate} strip /> / hour
      </div>
    </div>
  </Fragment>
);

export default ({ contractor, workLog, days }) => {

  const max = chain(days).map('minutes').max().value();
  const sum = chain(days).map('minutes').sum().value();

  return (
    <Fragment>
      <h1 className="header__title">
        {contractor.firstName} {contractor.lastName}
      </h1>

      <div className="columns">
        <div>
          <div className="sheet sheet--padding lg:hidden">
            <ContractorBlock contractor={contractor} />
          </div>
          <div className="sheet sheet--padding">
            <DateRange />
          </div>
          <div className="sheet sheet--padding">
            <ul className="flex items-end mt-4 mb-4">
              {map(days, (day, i) => (
                <li key={i} className="flex-1 relative px-2 border-b border-grayscale-divider" style={{ height: 180.0 * day.minutes / max }}>
                  <div className="bg-accent-light h-full bold-11 text-gray text-center border-t border-accent">
                    <div className="relative" style={{ bottom: 15 }}>
                      <Duration minutes={day.minutes} />
                    </div>
                    <div className="absolute left-0 right-0 text-center" style={{ bottom: -20 }}>
                      {day.day}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="sheet">
            <div className="sheet__title">
              Work log
            </div>
            <ul className="big-list">
              {map(workLog, (entry, i) => (
                <li key={i} className="big-list__item">
                  <img src={entry.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
                  <div className="big-list__text">
                    <span className="big-list__text__title">
                      <Link to={`/client/projects/${kebabCase(entry.project)}`}>
                        {entry.project}
                      </Link>
                    </span>
                    <div className="big-list__text__subtitle">
                      {entry.started_at}
                    </div>
                  </div>
                  <div className="big-list__text text-right pr-8">
                    <div className="big-list__text__title my-2">
                      <Duration minutes={entry.minutes} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="big-list__footer-link" />
          </div>
        </div>
        <div>
          <div className="sheet sheet--padding hidden lg:block">
            <ContractorBlock contractor={contractor} />
          </div>
          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Time worked this week
            </div>
            <div className="text-2xl font-bold text-center mt-1">
              <Duration minutes={sum} />
            </div>
          </div>
          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Earnings this week
            </div>
            <div className="text-2xl font-bold text-center mt-1">
              <Money amount={contractor.hourlyRate * sum / 60.0} />
            </div>
          </div>
          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Earnings this month
            </div>
            <div className="text-2xl font-bold text-center mt-1">
              <Money amount={contractor.hourlyRate * sum / 60.0 * 2.5} />
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};
