import { chain, map } from 'lodash';
import React, { Fragment } from 'react';

import { Duration, Money } from 'components/utilities';
import { DateRange } from 'components/shared';

export default ({ project, client, contractor, workLog, days }) => {

  const max = chain(days).map('minutes').max().value();
  const sum = chain(days).map('minutes').sum().value();

  return (
    <Fragment>
      <h1 className="header__title">
        Earnings
      </h1>

      <div className="columns">
        <div>

          <div className="sheet sheet--padding">
            <DateRange />
          </div>

          <div className="sheet sheet--padding">
            <div className="">
              <ul className="flex items-end mt-2 mb-4">
                {map(days, (day, i) => (
                  <li key={i} className="flex-1 relative px-2 border-b border-grayscale-divider" style={{ height: 180.0 * day.minutes / max }}>
                    <div className="bg-accent-light h-full bold-11 text-gray text-center border-t border-accent">
                      <div className="relative" style={{ bottom: 15 }}>
                        {day.minutes > 0 && <Duration minutes={day.minutes} />}
                      </div>
                      <div className="absolute left-0 right-0 text-center" style={{ bottom: -20 }}>
                        {day.day}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="sheet">
            <div className="sheet__title">
              Work log
            </div>
            <ul className="big-list">
              {map(workLog, (entry, i) => (
                <li key={i} className="big-list__item py-2">
                  <img src={entry.photoUrl} width={44} height={44} className="big-list__photo my-2" alt="" />
                  <div className="big-list__text -ml-4 mt-3">
                    <div className="big-list__text__title">
                      {entry.project}
                    </div>
                    <div className="big-list__text__subtitle">
                      {entry.started_at}
                    </div>
                  </div>
                  <div className="big-list__text text-right pr-8">
                    <div className="big-list__text__title mt-5">
                      <Duration minutes={entry.minutes} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="big-list__footer-link" />
          </div>

        </div>
        <div>

          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Time worked this week
            </div>
            <div className="text-2xl font-bold text-center mt-1">
              <Duration minutes={sum} />
            </div>
          </div>

          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Earned this week
            </div>
            <div className="text-2xl font-bold text-center mt-1">
              <Money amount={contractor.hourly * sum / 60.0} />
            </div>
          </div>

        </div>
      </div>

    </Fragment>
  );
};
