import React from 'react';
import { connect } from 'helpers';

import ContractorEarningsIndex from 'components/pages/contractor/earnings/index';

import gcm from 'tmp/gcm.png';
import monday from 'tmp/monday.png';

const props = {

  contractor: {
    hourly: 125,
  },

  days: [
    { day: 'Sun', minutes: 1 * 60 + 30 },
    { day: 'Mon', minutes: 7 * 60 + 20 },
    { day: 'Tue', minutes: 8 * 60 + 30 },
    { day: 'Wed', minutes: 6 * 60 + 20 },
    { day: 'Thu', minutes: 8 * 60 + 10 },
    { day: 'Fri', minutes: 7 * 60 +  0 },
    { day: 'Sat', minutes: 0 * 60 +  0 },
  ],

  workLog: [
    {
      project: 'Greek Capital Management',
      photoUrl: gcm,
      minutes: 1 * 60 + 28,
      started_at: 'Today at 12:10pm EEST',
    },
    {
      project: 'Monday',
      photoUrl: monday,
      minutes: 3 * 60 + 25,
      started_at: 'Yesterday at 11:42am EEST',
    },
    {
      project: 'Monday',
      photoUrl: monday,
      minutes: 5 * 60 + 37,
      started_at: 'Thursday at 9:38am EEST',
    },
    {
      project: 'Greek Capital Management',
      photoUrl: gcm,
      minutes: 3 * 60 + 51,
      started_at: 'Wednesday at 10:12am EEST',
    },
    {
      project: 'Greek Capital Management',
      photoUrl: gcm,
      minutes: 3 * 60 + 51,
      started_at: 'Tuesday at 9:29am EEST',
    },
  ],
};

class ContractorEarningsIndexContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Earnings' });
  }

  render() {
    return <ContractorEarningsIndex {...props} />;
  }

}

export default connect(ContractorEarningsIndexContainer);
