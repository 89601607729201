import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ClientLayout from 'components/pages/client/layout';

import UnauthenticatedAuthLogin from 'containers/unauthenticated/auth/login';

import ClientChatIndex from 'containers/client/chat/index';
import ClientContractorsIndex from 'containers/client/contractors/index';
import ClientContractorsShow from 'containers/client/contractors/show';
import ClientHelpIndex from 'containers/client/help/index';
import ClientProjectsIndex from 'containers/client/projects/index';
import ClientProjectsShow from 'containers/client/projects/show';
import ClientReportsIndex from 'containers/client/reports/index';
import ClientSettingsAccount from 'containers/client/settings/account';
import ClientSettingsPaymentMethods from 'containers/client/settings/payment-methods';
import ClientSettingsSubscription from 'containers/client/settings/subscription';
import ClientSettingsUsers from 'containers/client/settings/users';

import ContractorChatIndex from 'containers/contractor/chat/index';
import ContractorHelpIndex from 'containers/contractor/help/index';
import ContractorProjectsIndex from 'containers/contractor/projects/index';
import ContractorProjectsShow from 'containers/contractor/projects/show';
import ContractorEarningsIndex from 'containers/contractor/earnings/index';
import ContractorSettingsProfile from 'containers/contractor/settings/profile';
import ContractorSettingsPayoutMethods from 'containers/contractor/settings/payout-methods';

function AppRouter() {
  return (
    <Fragment>
      <Switch>
        <Route path="/login" exact component={UnauthenticatedAuthLogin} />

        <PrivateRoute path="/client/chat" exact component={ClientChatIndex} />
        <PrivateRoute path="/client/contractors" exact component={ClientContractorsIndex} />
        <PrivateRoute path="/client/contractors/:slug" exact component={ClientContractorsShow} />
        <PrivateRoute path="/client/help" exact component={ClientHelpIndex} />
        <PrivateRoute path="/client/projects" exact component={ClientProjectsIndex} />
        <PrivateRoute path="/client/projects/:slug" exact component={ClientProjectsShow} />
        <PrivateRoute path="/client/reports" exact component={ClientReportsIndex} />
        <PrivateRoute path="/client/settings/account" exact component={ClientSettingsAccount} />
        <PrivateRoute path="/client/settings/payment-methods" exact component={ClientSettingsPaymentMethods} />
        <PrivateRoute path="/client/settings/subscription" exact component={ClientSettingsSubscription} />
        <PrivateRoute path="/client/settings/users" exact component={ClientSettingsUsers} />
        <Redirect exact from="/client" to="/client/projects" />
        <Redirect exact from="/" to="/client/projects" />

        <PrivateRoute path="/contractor/chat" exact component={ContractorChatIndex} />
        <PrivateRoute path="/contractor/help" exact component={ContractorHelpIndex} />
        <PrivateRoute path="/contractor/projects" exact component={ContractorProjectsIndex} />
        <PrivateRoute path="/contractor/projects/:slug" exact component={ContractorProjectsShow} />
        <PrivateRoute path="/contractor/earnings" exact component={ContractorEarningsIndex} />
        <PrivateRoute path="/contractor/settings/profile" exact component={ContractorSettingsProfile} />
        <PrivateRoute path="/contractor/settings/payout-methods" exact component={ContractorSettingsPayoutMethods} />
        <Redirect exact from="/contractor" to="/contractor/projects" />

      </Switch>
    </Fragment>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = window.localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <ClientLayout>
            <Component {...props} />
          </ClientLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default AppRouter;
