import { chain, map } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Money, Duration } from 'components/utilities';
import { DateRange } from 'components/shared';

export default ({ payments, days, contractors, projects }) => {
  const { t } = useTranslation();

  const max = chain(days).map('minutes').max().value();
  const sum = chain(contractors).sumBy('minutes').value();

  return (
    <Fragment>
      <h1 className="header__title">
        {t('layout.Reports')}
      </h1>

      <div className="columns">
        <div>

          <div className="sheet sheet--padding">
            <DateRange />
          </div>

          <div className="sheet sheet--padding">
            <div>
              <ul className="flex items-end mt-2 mb-4">
                {map(days, (day, i) => (
                  <li key={i} className="flex-1 relative px-2 border-b border-grayscale-divider" style={{ height: 180.0 * day.minutes / max }}>
                    <div className="bg-accent-light h-full bold-11 text-gray text-center border-t border-accent">
                      <div className="relative" style={{ bottom: 15 }}>
                        <Duration minutes={day.minutes} />
                      </div>
                      <div className="absolute left-0 right-0 text-center" style={{ bottom: -20 }}>
                        {day.day}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="sheet">
            <div>
              <ul className="big-list">
                {map(projects, (project, i) => (
                  <li key={i} className="big-list__item">
                    <img src={project.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
                    <div className="big-list__text my-2">
                      <Link to={`/client/projects/${project.slug}`} className="big-list__text__title my-2">
                        {project.name}
                      </Link>
                    </div>
                    <div className="big-list__actions relative">
                      <FontAwesomeIcon icon={i < 2 ? faCheckCircle : faCircle} className="text-xl" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
        <div>
          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Time worked this week
            </div>
            <div className="text-4xl font-bold text-center mt-1">
              <Duration minutes={sum} />
            </div>
          </div>
          <div className="sheet sheet--padding">
            <div className="text-center bold-16 text-gray">
              Earnings this week
            </div>
            <div className="text-4xl font-bold text-center mt-1">
              <Money amount={sum * 45 / 60.0} />
            </div>
          </div><div className="sheet">
            <div className="sheet__title">
              Hours worked
            </div>
            <ul className="big-list">
              {map(contractors, (contractor, i) => (
                <li key={i} className="big-list__item">
                  <Link to={`/client/contractors/${contractor.slug}`}>
                    <img src={contractor.photoUrl} width={44} height={44} className="big-list__photo" alt="" />
                  </Link>
                  <div className="big-list__text">
                    <Link to={`/client/contractors/${contractor.slug}`} className="big-list__text__title">
                      {contractor.firstName} {contractor.lastName}
                    </Link>
                    <div className="big-list__text__subtitle">
                      ${contractor.hourlyRate} / hour {i === 0 && <small className="block">[could be a range]</small>}
                    </div>
                  </div>
                  <div className="big-list__text text-right pr-8">
                    <span className="big-list__text__title">
                      <Duration minutes={contractor.minutes} />
                    </span>
                    <div className="big-list__text__subtitle">
                      <Money amount={contractor.hourlyRate * contractor.minutes / 60.0} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="big-list__footer-link" />
          </div>
        </div>
      </div>

    </Fragment>
  );
};
