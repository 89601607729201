import { map } from 'lodash';
import cx from 'classnames';
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

const values = ['This week', 'Last week', 'This month', 'Last month', 'Year to date', 'Last year', 'All-time'];

export default ({ value, className }) => {
  const [ open, setOpen ] = useState(false);
  const [ selected, setSelected ] = useState(value);

  return (
    <div
      className={cx('cursor-pointer bg-grayscale-extralight semibold-16 text-grayscale-dark border border-grayscale-lightest inline-block px-4 py-1 rounded w-40 relative', className)}
      onClick={() => setOpen(!open)}
    >
      {selected}
      <div className="float-right">
        <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} className="ml-4 text-gray align-middle" />
      </div>
      {open && (
        <ul className="absolute border border-grayscale-divider rounded z-50 left-0 text-sm w-full" style={{ top: 40 }}>
          {map(values, (value, i) => (
            <li
              key={i}
              className="bg-white px-4 py-2 border-b border-grayscale-divider hover:bg-accent hover:text-white"
              onClick={() => setSelected(value)}
            >
              {value === selected ? (
                <strong>{value}</strong>
              ) : value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
