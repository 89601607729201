import { find } from 'lodash';
import React from 'react';
import { connect } from 'helpers';

import ClientProjectsShow from 'components/pages/client/projects/show';

const props = {

  workLog: [
    {
      firstName: 'Daria',
      lastName: 'Baltina',
      time: '8h 20m',
      date: 'today',
    },
    {
      firstName: 'Adam',
      lastName: 'West',
      time: '5h 10m',
      date: 'yesterday',
    },
    {
      firstName: 'April',
      lastName: 'Terry',
      time: '3h 5m',
      date: 'yesterday',
    },
    {
      firstName: 'Daria',
      lastName: 'Baltina',
      time: '4h 15m',
      date: 'Monday',
    },
  ],

  days: [
    { day: 'Sun', minutes:  4 * 60 + 30 },
    { day: 'Mon', minutes: 24 * 60 + 20 },
    { day: 'Tue', minutes: 12 * 60 + 30 },
    { day: 'Wed', minutes: 24 * 60 + 20 },
    { day: 'Thu', minutes: 19 * 60 + 55 },
    { day: 'Fri', minutes: 20 * 60 + 10 },
    { day: 'Sat', minutes:  5 * 60 + 15 },
  ],
};

class ClientProjectsShowContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Projects' });
  }

  render() {
    return <ClientProjectsShow {...props} {...this.props} />;
  }

}

const mapStateToProps = ({ projects }, ownProps) => {
  const { slug } = ownProps.match.params;
  const project = find(projects.data, { slug });
  return { project };
};

export default connect(ClientProjectsShowContainer, mapStateToProps);
