import { map } from 'lodash';
import cx from 'classnames';
import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChartLine, faSeedling, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import Nav from './_nav';

const plans = [
  {
    name: 'Startup',
    cost: 'Free',
    perMonth: false,
    icon: faSeedling,
    contractors: '2 contractors',
    projects: '1 project',
    users: '1 user',
    trial: 'Full-featured use',
    support: 'Live chat support',
    onboardingSpecialist: false,
  },
  {
    name: 'Growth',
    cost: '$39',
    perMonth: true,
    icon: faUsers,
    contractors: '5 contractors',
    projects: '1 project',
    users: '2 users',
    trial: '31-day free trial',
    support: 'Live chat support',
    onboardingSpecialist: false,
  },
  {
    name: 'Expansion',
    cost: '$99',
    perMonth: true,
    icon: faChartLine,
    contractors: '10 contractors',
    projects: '3 projects',
    users: '5 users',
    trial: '31-day free trial',
    support: '+ Phone support',
    onboardingSpecialist: true,
  },
  {
    name: 'Enterprise',
    cost: '$299',
    perMonth: true,
    icon: faBuilding,
    contractors: 'Unlimited contractors',
    projects: 'Unlimited projects',
    users: 'Unlimited users',
    trial: '31-day free trial',
    support: '+ Phone support',
    onboardingSpecialist: true,
  },
];

export default ({ projects }) => (
  <Fragment>
    <h1 className="header__title">
      Settings
    </h1>
    <Nav active="Subscription" />
    <div className="plans lg:flex mt-12">
      {map(plans, (plan, i) => (
        <div
          key={i}
          className={cx('plans__plan', {
            'plans__plan--active': plan.name === 'Startup',
          })}
        >
          <div className="plan__icon">
            <FontAwesomeIcon icon={plan.icon} />
          </div>
          <div className="plan__name">
            {plan.name}
          </div>
          <div className="plan__cost">
            {plan.cost}
            {plan.perMonth && (
              <span className="plan__per-month">
                / month
              </span>
            )}
          </div>
          <ul className="plan__features">
            <li className="plan__features__feature">
              <FontAwesomeIcon icon={faCheck} />
              {plan.contractors}
            </li>
            <li className="plan__features__feature">
              <FontAwesomeIcon icon={faCheck} />
              {plan.projects}
            </li>
            <li className="plan__features__feature">
              <FontAwesomeIcon icon={faCheck} />
              {plan.users}
            </li>
            <li className="plan__features__feature">
              <FontAwesomeIcon icon={faCheck} />
              {plan.trial}
            </li>
            <li className="plan__features__feature">
              <FontAwesomeIcon icon={faCheck} />
              {plan.support}
            </li>
            <li className={cx('plan__features__feature', {
              'plan__features__feature--not-included': !plan.onboardingSpecialist,
            })}>
              <FontAwesomeIcon icon={faCheck} />
              Onboarding specialist
            </li>
          </ul>
          <button className="plan__button btn-accent">
            {plan.name === 'Startup' ? 'Current plan' : 'Select plan'}
          </button>
        </div>
      ))}
    </div>
  </Fragment>
);
