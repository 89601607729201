import React from 'react';
import { connect } from 'helpers';

import ClientSettingsPaymentMethods from 'components/pages/client/settings/payment-methods';

const props = {

  paymentMethods: [
    {
      company: 'AmEx',
      last4: '1924',
      primary: true,
      expiration_mo: '02',
      expiration_yr: '23',
    },
    {
      company: 'Visa',
      last4: '0848',
      expiration_mo: '04',
      expiration_yr: '22',
    },
    {
      company: 'Chase',
      last4: '0183',
    },
  ],

  payments: [
    {
      type: 'bank',
      company: 'Chase',
      last4: '8492',
      amount: 1235.90,
      created_at: '2019-08-26',
    },
    {
      type: 'mastercard',
      company: 'Mastercard',
      last4: '4912',
      amount: 1420.53,
      created_at: '2019-08-19',
    },
    {
      type: 'mastercard',
      company: 'Mastercard',
      last4: '4912',
      amount: 950.12,
      created_at: '2019-08-12',
    },
    {
      type: 'mastercard',
      company: 'Mastercard',
      last4: '4912',
      amount: 1194.42,
      created_at: '2019-08-07',
    },
  ],

};

class ClientSettingsPaymentMethodsContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Settings' });
  }

  render() {
    return <ClientSettingsPaymentMethods {...props} />;
  }

}

export default connect(ClientSettingsPaymentMethodsContainer);
