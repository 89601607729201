import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';

import Select from 'react-select';

import Nav from './_nav';

import todd from 'tmp/todd.png';

const countries = countryList().getData();

export default () => {
  const { country, setCountry } = useState();
  const { t } = useTranslation();

  return (
    <Fragment>
      <h1 className="header__title">
        {t('layout.Settings')}
      </h1>
      <Nav active="Profile" />
      <div className="sheet sheet--padding my-8 mx-8">
        <div className="flex-1 sheet__title">
          Profile
        </div>

        <div className="flex">
          <div className="form__form-group flex-1 mr-8">
            <label className="uppercase">
              First name
            </label>
            <input type="text" />
          </div>

          <div className="form__form-group flex-1">
            <label className="uppercase">
              Last name
            </label>
            <input type="text" />
          </div>
        </div>

        <div className="form__form-group w-1/2 pr-4">
          <label className="uppercase">
            Email address
          </label>
          <input type="text" />
        </div>

        <div className="flex">
          <div className="form__form-group flex-1 mr-8">
            <label className="uppercase">
              City
            </label>
            <input type="text" />
          </div>

          <div className="form__form-group flex-1">
            <label className="uppercase">
              Country
            </label>
            <Select
              options={countries}
              value={country}
              onChange={setCountry}
              classNamePrefix="react-select"
            />
          </div>
        </div>

        <div className="form__form-group w-1/2 pr-4">
          <label className="uppercase">
            Timezone
          </label>
          <Select
            options={countries}
            value={country}
            onChange={setCountry}
            classNamePrefix="react-select"
          />
        </div>

        <button className="btn-default">Save profile</button>

      </div>
      <div className="columns">
        <div className="sheet sheet--padding">
          <div className="sheet__title">
            Security
          </div>

          <div className="form__form-group w-1/2">
            <label className="uppercase">
              Current password
            </label>
            <input type="text" />
          </div>

          <div className="form__form-group w-1/2">
            <label className="uppercase">
              New password
            </label>
            <input type="text" />
          </div>

          <div className="form__form-group w-1/2">
            <label className="uppercase">
              Confirm password
            </label>
            <input type="text" />
          </div>

          <button className="btn-default">Update password</button>
        </div>
        <div className="sheet sheet--padding">
          <div className="sheet__title">
              Your photo
          </div>
          <div className="mb-6">
            <img src={todd} width={120} height={120} alt="" className="rounded-full" />
          </div>
          <button className="btn-default">Select a new photo</button>
        </div>
      </div>
    </Fragment>
  );
};
