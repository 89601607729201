import T from 'actions/types';

import gcm from 'tmp/gcm.png';
import monday from 'tmp/monday.png';
import synack from 'tmp/synack.png';
import todd from 'tmp/todd.png';

import { contractors } from './contractors';

const projects = [
  {
    id: 1,
    name: 'Greek Capital Management',
    slug: 'greek-capital-management',
    photoUrl: gcm,
    contractors,
    clients: [
      {
        firstName: 'Todd',
        lastName: 'Zusman',
        photoUrl: todd,
      },
    ],
  },
  {
    id: 2,
    name: 'Monday',
    slug: 'monday',
    photoUrl: monday,
    contractors,
    clients: [
      {
        firstName: 'John',
        lastName: 'Smith',
      },
    ],
  },
  {
    id: 3,
    name: 'Synack',
    slug: 'synack',
    photoUrl: synack,
    contractors,
    clients: [
      {
        firstName: 'Amy',
        lastName: 'Gallagher',
        photoUrl: todd,
      },
    ],
  },
];

const initialState = {
  data: projects,
};

export default (state=initialState, action) => {
  if (action.type !== T.SET_PROJECTS) {
    return state;
  }

  return action.projects;
};
