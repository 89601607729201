import React from 'react';
import { connect } from 'helpers';

import ClientProjectsIndex from 'components/pages/client/projects/index';

class ClientProjectsIndexContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Projects' });
  }

  render() {
    return <ClientProjectsIndex {...this.props} />;
  }

}

const mapStateToProps = ({ projects }) => Object.assign({}, {
  projects: projects.data,
});

export default connect(ClientProjectsIndexContainer, mapStateToProps);
