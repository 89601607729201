import React from 'react';
import { connect } from 'helpers';

import ContractorProjectsShow from 'components/pages/contractor/projects/show';

import todd from 'tmp/todd.png';
import gcm from 'tmp/gcm.png';

const props = {

  project: {
    name: 'Greek Capital Management',
    photoUrl: gcm,
  },

  client: {
    firstName: 'Todd',
    lastName: 'Zusman',
    photoUrl: todd,
    location: 'Boulder, Colorado',
  },

  contractor: {
    hourly: 125,
  },

  days: [
    { day: 'Sun', minutes: 1 * 60 + 30 },
    { day: 'Mon', minutes: 4 * 60 + 20 },
    { day: 'Tue', minutes: 8 * 60 + 30 },
    { day: 'Wed', minutes: 5 * 60 + 20 },
    { day: 'Thu', minutes: 6 * 60 + 55 },
    { day: 'Fri', minutes: 3 * 60 + 10 },
    { day: 'Sat', minutes: 0 * 60 + 50 },
  ],

  workLog: [
    {
      minutes: 1 * 60 + 28,
      started_at: 'Today at 12:10pm EEST',
    },
    {
      minutes: 3 * 60 + 25,
      started_at: 'Yesterday at 11:42am EEST',
    },
    {
      minutes: 5 * 60 + 37,
      started_at: 'Thursday at 9:38am EEST',
    },
    {
      minutes: 3 * 60 + 51,
      started_at: 'Wednesday at 10:12am EEST',
    },
    {
      minutes: 3 * 60 + 51,
      started_at: 'Tuesday at 9:29am EEST',
    },
  ],
};

class ContractorProjectsShowContainer extends React.Component {

  componentDidMount() {
    const { actions } = this.props;
    actions.setLayoutProps({ sidebar: 'Projects' });
  }

  render() {
    return <ContractorProjectsShow {...props} />;
  }

}

export default connect(ContractorProjectsShowContainer);
